import '../css/Device.css';
import {useEffect, useState} from "react";
import axios from "axios";
import IcBack from '../assets/image/icon_back.png';
import IcSCheck from '../assets/image/icon_sendcheck.png';

const DeviceModal = ({isShow, callback}) => {

    const [category, setCategory] = useState([]);
    const [select, setSelect] = useState(0);

    const sendSelect = () => {
        const result = {
            error: false,
            message: '',
        };

        let selctval = [];
        selctval.push({
            type: 'main',
            code: select,
            value: category[select].title,
            image: category[select].image,
        });

        for (let j = 0; j < category[select].type.length; j++) {
            let count = 0;
            for (let k = 0; k < category[select].type[j].subdtype.length; k++) {
                if (
                    category[select].type[j].select &&
                    category[select].type[j].select[k]
                ) {
                    selctval.push({
                        type: category[select].type[j].title,
                        code: j,
                        subcode: k,
                        value: category[select].type[j].subdtype[k],
                    });
                    count++;
                }
            }
            if (category[select].type[j].notnull && count === 0) {
                result.error = true;
                result.message = category[select].type[j].title + '를(을) 선택해주세요.';
                break;
            }
        }

        if (result.error) {
            window.alert(result.message);
        } else {
            callback('select', selctval);
        }
    };

    const loadCategory = async () => {
        const result = await axios.get("/dcategory");
        console.log(result);
        if (result.status && result.status === 200) {
            const data = result.data;
            if (data && data.status === 200) {
                const indata = JSON.parse(data.result);
                for (let i = 0; i < indata.length; i++) {
                    for (let j = 0; j < indata[i].type.length; j++) {
                        indata[i].type[j].select = [];
                        for (let k = 0; k < indata[i].type[j].subdtype.length; k++) {
                            indata[i].type[j].select[k] = false;
                        }
                    }
                }

                setCategory(indata);
            }
        }
    }

    const slectSubdtype = (index, index2) => {
        const setval = [...category];

        if (!setval[select].type[index].multi) {
            if (!setval[select].type[index].select[index2]) {
                for (let i = 0; i < setval[select].type[index].select.length; i++) {
                    setval[select].type[index].select[i] = false;
                }
            }
        }

        setval[select].type[index].select[index2] =
            !setval[select].type[index].select[index2];

        setCategory(setval);
    };

    useEffect(() => {
        if (isShow) {
            setSelect(0);
            loadCategory();
        }
    }, [isShow]);

    return (
        <div className="deviceBox" style={{display: isShow ? 'block' : 'none'}}>
            <div className="deviceOutBox">
                <div className="deviceBg">
                    <div className="deviceHeader">
                        <button onClick={(e) => {
                            e.preventDefault();
                            callback('close');
                        }}><img src={IcBack}/></button>
                        <h2>장비선택</h2>
                    </div>
                    <div className="deviceBody">
                        <div className="deviceCategory">
                            {category.length > 0 && (
                                <>
                                    {category.map((item, index) => (
                                        <button key={index} className={select === index ? 'active' : ''}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelect(index);
                                                }}>
                                            <span>{item.title}</span>
                                        </button>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className="deviceCategoryInfo">
                            {category.length > 0 &&
                                category[select].type &&
                                category[select].type.length > 0 && (
                                    <>
                                        {category[select].type.map((item, index) => (
                                            <div className={index !== 0 ? 'underline' : ''} key={index}>
                                                <h3><span>{item.title}</span>
                                                    {item.notnull && <span className="important">*</span>}
                                                    {item.multi && <span className="multi">복수선택가능</span>}
                                                </h3>
                                                {item.subdtype && item.subdtype.length > 0 && (
                                                    <div className="deviceCategoryInfoItems">
                                                        {item.subdtype.map((item2, index2) => (
                                                            <button key={index2} onClick={(e) => {
                                                                slectSubdtype(index, index2);
                                                            }} className={item.select[index2] ? 'active' : ''}><span>{item2}</span></button>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                )}
                        </div>
                    </div>
                    <div className="deviceBottom">
                        <button onClick={(e) => {
                            e.preventDefault();
                            sendSelect();
                        }}>
                            <img src={IcSCheck} />
                            <span>저장</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeviceModal;
