import IcMainLocation from "../assets/image/icon_main_location.png";
import IcMainDevice from "../assets/image/icon_main_device.png";
import IcMainDriver from "../assets/image/icon_main_driver.png";
import IcMainCalenda from "../assets/image/icon_main_calenda.png";

const LiveNowItem = ({item, index}) => {

    const SELECT = {
        datetype: {
            title: '기간',
            items: [
                {code: 1, value: '기간'},
                {code: 2, value: '하루'},
                {code: 3, value: '반일'},
            ],
        }
    };

    const diffDate = (date1, date2) => {
        let diffdate1 = date1 instanceof Date ? date1 : new Date(date1);
        let diffdate2 = date2 instanceof Date ? date2 : new Date(date2);
        diffdate1 = new Date(
            diffdate1.getFullYear(),
            diffdate1.getMonth() + 1,
            diffdate1.getDate(),
        );
        diffdate2 = new Date(
            diffdate2.getFullYear(),
            diffdate2.getMonth() + 1,
            diffdate2.getDate(),
        );

        let diff = Math.abs(diffdate2.getTime() - diffdate1.getTime());
        diff = Math.ceil(diff / (1000 * 3600 * 24));

        return diff;
    };

    let datestring = item.start.replace(/-/g, '.').substring(2, 10);

    if (item.dtype === SELECT.datetype.items[2].code) {
        datestring += ' (' + SELECT.datetype.items[2].value + ')';
    } else if (item.dtype === SELECT.datetype.items[1].code) {
        datestring += ' (' + SELECT.datetype.items[1].value + ')';
    } else {
        const day = diffDate(item.end, item.start) + 1;
        datestring +=
            ' ~ ' + item.end.replace(/-/g, '.').substring(2, 10) + ' (' + day + '일)';
    }

    return (
        <div className="live_now_item" style={{
            backgroundImage: "url(" + IcMainLocation + ")",
            backgroundPositionX: '10px',
            backgroundPositionY: '7px',
            backgroundRepeat: 'no-repeat',
        }}>
            <span className="live_now_address">{item.addr}</span>
            <div className="live_now_inbox">
                <div className="live_now_title">
                    <span className="live_now_icons">
                        {item.donly === 0 && (
                            <img src={IcMainDevice} alt="장비"/>
                        )}
                        <img src={IcMainDriver} className="driver" alt="드라이버"/>
                    </span>
                    <span className="live_now_intitle">{item.donly ? '스페어 (기사)' : '임대 (장비+기사)'}</span>
                    <span className="pipe">|</span>
                    <span className="live_now_device">{item.device}</span>
                </div>
                <div className="live_now_date"><span><img src={IcMainCalenda} alt="달력"/></span><span>{datestring}</span></div>
                <div className="live_now_contents">{item.contents}</div>
            </div>
        </div>
    )
}

export default LiveNowItem;
