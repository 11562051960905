import '../css/Address.css';
import {useEffect, useState} from "react";
import DaumPostcode from 'react-daum-postcode';
import IcBack from "../assets/image/icon_back.png";

const AddressModal = ({isShow, callback}) => {

    useEffect(() => {

    }, [isShow]);

    return(
        <div className="addressBox2" style={{display: isShow ? 'block' : 'none'}}>
            <div className="addressOutBox">
                <div className="addressBg">
                    <div className="addressHeader">
                        <button onClick={(e) => {
                            e.preventDefault();
                            callback('close');
                        }}><img src={IcBack}/></button>
                        <h2>주소검색</h2>
                    </div>
                    <div className="addressBody">
                        <DaumPostcode
                            autoClose
                            onComplete={(data) => {
                                callback('select', data);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressModal;
