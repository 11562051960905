import '../css/Modal.css';
import IcClose from '../assets/image/icon_close.png';
import IcAlert from '../assets/image/icon_alert.png';
import IcCheckN from '../assets/image/icon_check_normal.png';
import IcCheckA from '../assets/image/icon_check_active.png';
import {useEffect, useState} from "react";
import IcQuest from "../assets/image/icon_main_quest.png";
import IcWClose from "../assets/image/icon_wclose.png";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/locale/ko";
import 'react-datepicker/dist/react-datepicker.css'
import DeviceModal from "./DeviceModal";
import AddressModal from "./AddressModal";
import axios from "axios";

const OrderModal = ({isShow, callback}) => {

    const [isDevice, setIsDevice] = useState(false);
    const [isAddress, setIsAddress] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [device, setDevice] = useState([]);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState(1);
    const [dayType, setDayType] = useState(1);
    const [address, setAddress] = useState({});
    const [ordertype, setOrdertype] = useState(1);
    const [contents, setContents] = useState('');

    const formatDate = (date, char = '-') => {
        let cutend = date instanceof Date ? date : new Date(date);
        let offset = cutend.getTimezoneOffset() * 60000;
        cutend = new Date(cutend.getTime() - offset);
        return cutend.toISOString().substring(0, 10).replace(/-/g, char);
    };

    const sendOrder = async () => {
        let error = {
            error: false,
            message: '',
        };
        if (!error.error && !name) {
            error.error = true;
            error.message = '성명을 입력해 주세요.';
        }
        if (!error.error && !mobile) {
            error.error = true;
            error.message = '전화번호를 입력해 주세요.';
        }
        if (!error.error) {
            const result = await axios.post('/estimate/add', {
                name: name,
                mobile: mobile,
                devicetype: device.length > 0 ? JSON.stringify(device) : '',
                datestart: start ? formatDate(start) : null,
                range: end,
                time: dayType,
                zip: address.zipcode ? address.zipcode : '',
                addr: address.address ? address.address : '',
                donly: ordertype,
                content: contents,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log(result);

            if (result.status && result.status === 200) {
                const data = result.data;
                if (data && data.status === 200) {
                    callback('close');
                    window.alert('견적이 요청되었습니다.\n요청하신 내용으로 장파 매니저가\n최대한 빠른 시간 내에 연락 예정 입니다');
                }
            }
        } else if(error.message) {
            window.alert(error.message);
        }
    };

    useEffect(() => {
        if (isShow) {
            setName('');
            setMobile('');
            setDevice([]);
            setStart('');
            setEnd(1);
            setDayType(1);
            setAddress({});
            setOrdertype(1);
            setContents('');
        }
    }, [isShow]);

    let days = [];
    if (dayType === 1) {
        for (let i = 1; i <= 31; i++) {
            days = [...days, i];
        }
    } else if (dayType === 2) {
        for (let i = 1; i <= 12; i++) {
            days = [...days, i];
        }
    } else {
        days = [1];
    }

    return (
        <>
            <div className="modalBox" style={{display: isShow ? 'block' : 'none'}}>
                <div className="modalOutBox">
                    <div className="modalBg">
                        <div className="modalHeader">
                            <h2>견적문의하기</h2>
                            <div className="alertBox">
                                <img src={IcAlert}/>
                                <span>장파 매니저가 업무 내용을 상담 드릴 예정입니다.<br/>알고 계신 만큼만 작성하여 견적문의 해주세요. 상담시 내용을 보완해 드립니다.</span>
                            </div>
                            <button className="modalClose" onClick={(e) => {
                                e.preventDefault();
                                callback('close');
                            }}><img src={IcClose}/></button>
                        </div>
                        <div className="modalBody">
                            <dl className="inputBox">
                                <dt className="w15"><span>성명(업체명)</span><span className="important">*</span></dt>
                                <dd className="w35">
                                    <input type="text" value={name} placeholder="성명(업체명)" onChange={(e) => {
                                        setName(e.target.value);
                                    }}/>
                                </dd>
                                <dt className="w15"><span>전화번호</span><span className="important">*</span></dt>
                                <dd className="w35">
                                    <input type="text" value={mobile} placeholder="(-)없이 입력" onChange={(e) => {
                                        setMobile(e.target.value);
                                    }}/>
                                </dd>
                            </dl>
                            <dl className="inputBox">
                                <dt className="w15"><span>장비선택</span></dt>
                                <dd className="w85">
                                    {device.length > 0 ? (
                                        <div className="deviceSelectBox">
                                            <span className="deviceSelectTitle">{device[0].value}</span>
                                            {device.length > 1 && (
                                                <span className="deviceSelectTag">{device[1].value}</span>)}
                                            {device.length > 2 && (
                                                <span className="deviceSelectTag">
                                                    {device.map((item, index) => (
                                                        <>
                                                            {index > 1 && (index > 2 ? ', ' + item.value : item.value)}
                                                        </>
                                                    ))}
                                                </span>
                                            )}
                                            <button className="deviceSelectDel" onClick={(e) => {
                                                e.preventDefault();
                                                setDevice([]);
                                            }}>
                                                <img src={IcWClose}/>
                                            </button>
                                        </div>
                                    ) : (
                                        <button className="deviceButton" onClick={(e) => {
                                            e.preventDefault();
                                            setIsDevice(true);
                                        }}>장비를 선택해주세요
                                        </button>
                                    )}
                                </dd>
                                <dt className="w15"><span>작업기간</span></dt>
                                <dd className="workBox w85">
                                    <div className="subtitleBox">
                                        <span className="subTitle">작업 시작일</span>
                                        <DatePicker
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="시작일 선택"
                                            selected={start ? start : ''}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                setStart(date);
                                            }}
                                            className="carendaButton"
                                            onKeyDown={e => e.preventDefault()}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="subtitleBox">
                                        <span className="subTitle">작업 기간</span>
                                        <select className="daySelect" value={end} onChange={(e) => {
                                            setEnd(parseInt(e.target.value));
                                        }}>
                                            {days.map((item, index) => (
                                                <option key={index} value={item}>{item}</option>
                                            ))}
                                        </select>
                                        <select className="dayType" value={dayType} onChange={(e) => {
                                            setDayType(parseInt(e.target.value));
                                        }}>
                                            <option value="1">일</option>
                                            <option value="2">개월</option>
                                            <option value="3">오전 반나절</option>
                                            <option value="4">오후 반나절</option>
                                        </select>
                                    </div>
                                    <div className="subtitleBox">
                                        <span className="subTitle"></span>
                                        <span className="subInfo">작업 기간, 작업 위치에 따라<br />견적단가 변동이 있습니다</span>
                                    </div>
                                </dd>
                                <dt className="w15"><span>위치</span></dt>
                                <dd className="addressBox w85">
                                    <input type="text" value={address.address ? address.address : ''}
                                           placeholder="시군구읍면동 등 지역명 또는 도로명 입력" onChange={(e) => {
                                        setAddress((prev) => ({...prev, address: e.target.value}));
                                    }}/>
                                    <button className="searchAddress" onClick={(e) => {
                                        e.preventDefault();
                                        setIsAddress(true);
                                    }}><span>주소검색</span></button>
                                </dd>
                                <dt className="w15"><span>요청구분</span></dt>
                                <dd className="w85 tabBox">
                                    <button className={ordertype === 1 ? 'tabButton active' : 'tabButton'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOrdertype(1);
                                            }}>
                                        <img src={ordertype === 1 ? IcCheckA : IcCheckN}/>
                                        <span>임대 (장비+기사)</span>
                                    </button>
                                    <button className={ordertype === 2 ? 'tabButton active' : 'tabButton'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOrdertype(2);
                                            }}>
                                        <img src={ordertype === 2 ? IcCheckA : IcCheckN}/>
                                        <span>스페어 (기사)</span>
                                    </button>
                                    <button className={ordertype === 3 ? 'tabButton active' : 'tabButton'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOrdertype(3);
                                            }}>
                                        <img src={ordertype === 3 ? IcCheckA : IcCheckN}/>
                                        <span>렌탈 (장비)</span>
                                    </button>
                                </dd>
                                <dt className="w15"><span
                                    style={{
                                        lineHeight: '1.2em',
                                        width: '80px',
                                        display: 'inline-block'
                                    }}>작업내용 및 기타요청</span>
                                </dt>
                                <dd className="w85 contentsBox">
                                <textarea
                                    placeholder={'세부요청사항을 간략하게 입력해주세요\n예시) 작업내용, 차량조건 및 어태치업션, 운행필요 유무, 희망단가, 기사 조건,\n필요서류, 작업시간, 지불조건, 현장 특수사항, 기타사항 등등'}
                                    value={contents} onChange={(e) => {
                                    setContents(e.target.value);
                                }}></textarea>
                                </dd>
                                <div className="important">
                                    <span>*</span>
                                    <span>필요 장비, 작업 일시, 작업 기간, 위치, 기타  요청 사항 등을 기입해 주세요</span>
                                </div>
                            </dl>
                        </div>
                        <div className="modalBottom">
                            <button className="sendButton" onClick={(e) => {
                                e.preventDefault();
                                sendOrder();
                            }}><img src={IcQuest} alt="견적"/><span>견적 문의하기</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <DeviceModal isShow={isDevice} callback={(type, data) => {
                switch (type) {
                    case 'close' :
                        setIsDevice(false);
                        break;
                    case 'select' :
                        setDevice(data);
                        setIsDevice(false);
                        break;
                }
            }}/>
            {isAddress && (
                <AddressModal isShow={isAddress} callback={(type, data) => {
                    switch (type) {
                        case 'close' :
                            setIsAddress(false);
                            break;
                        case 'select' :
                            setAddress({
                                zipcode: data.zonecode,
                                address: data.address,
                            });
                            setIsAddress(false);
                            break;
                    }
                }}/>
            )}
        </>
    );
}

export default OrderModal;
